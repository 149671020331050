import React, { Component } from 'react';
import { navigate } from "gatsby"


import './Calculator.css';
import diamondWhiteRight from './dia-white-right.png';
import diamondWhiteCenter from './dia-white-center.png';
import diamondWhiteLeft from './dia-white-left.png';
import diamondBlueCenter from './dia-blue-center.png'; 

import Button from '../Layout/Button/Button'


class Calculator extends Component {

    constructor(props){
        super(props);

        this.wsPrice = .85;         // wholesale
        this.cPrice = .90;          // consignment
        this.sPrice = .94;          // summon  6%
        this.pPrice = 1;            // private
        this.adCost = 1000;         // ad costs for private and consignment
        this.summonListing = 950;   // summon listing cost
        
        this.state = {
            placeholder: '',
            wholesale: '$',
            consignment: '$',
            rheels: '$',
            private: '$',
            estimatedPrice: '$',
            typeIndex: 0
          };

        this.carEstimateEl = React.createRef();
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.typeValue = this.typeValue.bind(this);
    }

    componentDidMount() {
        
        // animate 'type' placeholder value
        this.typeID = setInterval(
          () => this.typeValue(),
          150
        );

      }

      componentWillUnmount() {
        clearInterval(this.typeID);
      }

      typeValue () {

        var positionFromTop = document.getElementById("carEst").getBoundingClientRect().top;

         if (positionFromTop > 0 && positionFromTop < window.innerHeight) {
            let i = this.state.typeIndex;
            var txt = 'Enter car value here';
            if(i < txt.length + 1) {
                let newTxt = txt.substring(0, i);
                this.setState({placeholder: newTxt});
                i++;
                this.setState({typeIndex: i});
            }   
        } 

      }

    formatNumber = (n) => {
        // format number 1000000 to 1,234,567
        let i = n.replace(/^[0|\D]*/,'');
        return i.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }

      unformatNumber = (currency) => {
        currency = Number(currency.replace(/[^0-9.-]+/g,""));
        return parseFloat(currency);
      }
      


    submitHandler = (event) => {
        event.preventDefault(); // stop form loading new page 
        //const estimate = this.carEstimateEl.current.value;
    };


    handleOnClick = (event) => {
        this.setState({ placeholder: "$"});
    }



    handleKeyUp = (event) => {
        //console.log('Ev' + event.target.value)

        //event.target.value += this.formatNumber(1);

        // appends $ to value, validates decimal side
        // and puts cursor back in right position.
        
        // get input value
        var input_val = event.target.value;
        
        // don't validate empty input
        if (input_val === "") { return; }
        
        // original length
        var original_len = input_val.length;
      
        // initial caret position 
        var caret_pos = event.target.selectionStart;
          
        // check for decimal
        if (input_val.indexOf(".") >= 0) {
      
          // get position of first decimal
          // this prevents multiple decimals from
          // being entered
          var decimal_pos = input_val.indexOf(".");
      
          // split number by decimal point
          var left_side = input_val.substring(0, decimal_pos);
          var right_side = input_val.substring(decimal_pos);
      
          // add commas to left side of number
          left_side = this.formatNumber(left_side);
      
          // validate right side
          right_side = this.formatNumber(right_side);
          
          // On blur make sure 2 numbers after decimal
          if (event.target.blur === "blur") {
            right_side += "00";
          }
          
          // Limit decimal to only 2 digits
          right_side = right_side.substring(0, 2);
      
          // join number by .
          input_val = "$" + left_side + "." + right_side;
      
        } else {
          // no decimal entered
          // add commas to number
          // remove all non-digits

          input_val = this.formatNumber(input_val);
          input_val = "$" + input_val;

          let estimatedValue = this.unformatNumber(input_val);

          let wholesaleValue = Math.round(estimatedValue * this.wsPrice ); // wholesale 
          wholesaleValue = this.formatNumber(String(wholesaleValue));
          
          let consignmentValue = Math.round((estimatedValue * this.cPrice) - this.adCost); // consignment 
          consignmentValue = this.formatNumber(String(consignmentValue));

          let rheelsValue = Math.round((estimatedValue * this.sPrice) - this.summonListing); // summon 
          rheelsValue = this.formatNumber(String(rheelsValue));

          let privateValue = Math.round((estimatedValue * this.pPrice) - this.adCost); // private

          privateValue = this.formatNumber(String(privateValue));

          let customerEstimation = Math.round(estimatedValue);
          customerEstimation = this.formatNumber(String(customerEstimation));

          this.setState({
              wholesale: "$" + wholesaleValue,
              consignment: "$" + consignmentValue,
              rheels: "$" + rheelsValue,
              private: "$" + privateValue,
              estimatedPrice: "$" + customerEstimation
        });
            

          // final formatting
          if (event.target.blur === "blur") {
            input_val += ".00";
          }
        }
        
        // send updated string to input
        event.target.value = input_val;
      
        // put caret back in the right position
        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        event.target.setSelectionRange(caret_pos, caret_pos);

    };


    render() {
          const handleClick = () => {
      //navigate('/enquiry/');
      navigate('https://value.summon.com.au/');
    };
        return (

            <div className="calculator">


                <div className="estimate-input">
                    <form onSubmit={this.submitHandler}>
                        <input type="text" title="Enter car value here" id="carEst" ref={this.carEstimateEl} onClick={this.handleOnClick} onKeyUp={this.handleKeyUp}  pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"  data-type="currency" autoComplete="off" maxLength="8" placeholder={this.state.placeholder} />
                        <p className="enter-estimate">Enter the estimated current value of your car (minimum $50,000)</p>
                    </form>
                    <h3>From a {this.state.estimatedPrice} sale price, you could get...</h3>
                </div>

                <div className="options">

                        <div className="sell-option align-right">
                            <img className="diamond" src={diamondWhiteLeft} alt="" />
                            <h3>{this.state.wholesale}</h3>
                            <strong>Sell to a Dealer</strong>
                            <p>You sell your car or trade-in to a<br />  dealer at a wholesale price<sup>1</sup></p>
                    
                            <ul>
                                <li className="white-tick">Your car is sold immediately</li>
                                <li className="white-tick">The dealer helps with paperwork</li>
                                <li className="white-cross">You don’t get the best price</li>
                            </ul>
                            
                        </div>

                        <div className="sell-option align-left">
                            <img className="diamond center" src={diamondWhiteCenter} alt="" />
                            <h3>{this.state.consignment}</h3>
                            <strong>Consignment</strong>
                            <p>You place your car on<br />  consignment with a dealer<sup>2</sup></p>
                            <ul>
                                <li className="white-cross">You’re without your car until it sells</li>
                                <li className="white-cross">Not legal in Victoria</li>
                                <li className="white-tick">The dealer fields enquiries</li>
                                <li className="white-tick">The dealer arranges test-drives</li>
                                <li className="white-tick">The dealer helps with paperwork</li>
                            </ul>
                        
                        </div>

                        <div className="sell-option highlight align-right">
                            <img className="diamond center" src={diamondBlueCenter} alt="" />
                            <h3 className="blue">{this.state.rheels}</h3>
                            <strong className="blue">Summon</strong>
                            <p className="blue">We advertise and sell<br />  your car for you</p>
                            <ul>
                                <li className="gold-tick">You keep your car until sold</li>
                                <li className="gold-tick">We take photos & create your ad</li>
                                <li className="gold-tick">We collect the car from your home</li>
                                <li className="gold-tick">Leave the test-drives to us</li>
                                <li className="gold-tick">We help with the $$ and paperwork</li>
                            </ul>

                            <Button onClick={handleClick}>Enquire Now</Button>
                            
                        </div>

                        <div className="sell-option align-left">
                            <img className="diamond right" src={diamondWhiteRight} alt="" />
                            <h3>{this.state.private}</h3>
                            <strong>Sell Privately</strong>
                            <p>You advertise and sell<br />  your car yourself <sup>3</sup></p>
                            <ul>
                                <li className="white-tick">No  dealer fees or commissions</li>
                                <li className="white-cross">You have to take photos and create an ad</li>
                                <li className="white-cross">You field time-wasting calls and emails</li>
                                <li className="white-cross">You have to organise and do test-drives</li>
                                <li className="white-cross">You have to safely get ${this.state.private} from the buyer</li>
                            </ul>
                        </div>

                </div>
                <p className="calculator-note">1. Average 15% less than private seller price based on Summon research.    2. Typical  $1,000 listing fee + 10% commission.    3. After costs of ad listing fees, detailing, petrol and roadworthy  </p>                
            </div>


        );
    }
}


export default Calculator;


