import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import Calculator from '../../components/Calculator/Calculator'
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'
import { Link } from "gatsby"

const calculatorPage = ({location}) => 
  (
  <Layout>

    <SEO  title="Calculator: Selling Privately vs Selling to a Dealer" 
          description="Online calculator showing the value of selling with Summon versus dealer or wholesale"
          location={location}
    />

    <PageHeader
						title="Calculator: selling private vs dealer"
            text="If you are weighing up what the best way to sell your prestige car might be, this calculator is here to help. 
            Whether you are selling an Audi, a BMW or a Porsche - the discount you pay when selling to a Dealer can be hefty."
		/>

    <SectionPageMargin>
      <div>
        <Heading2>Selling your prestige car</Heading2>

        <Paragraph1>If you are weighing up what the best way to sell your prestige car might be, this calculator is here to help. 
          Whether you are selling an Audi, a BMW or a Porsche - the discount you pay when selling to a Dealer can be hefty. 
          But of course, it’s also really convenient and you get cash for your car quickly. If you’re not in a hurry, 
          selling your prestige car privately might be the best option, if you’re prepared to put in the time and effort yourself. 
          For everyone else… well that’s why Summon exists.</Paragraph1>
        <Paragraph1>Use the calculator below to get a guide for how much you could get for your car 
          if you sold it privately, to a dealership, to a dealer on consignment or with Summon.</Paragraph1>
        <Paragraph1>Start by entering the estimated value of your prestige car. If you need help with working out an estimated value, 
          either <strong><Link to="/contact/">contact us</Link></strong> or read through our recommendations in 
          our <Link title="2020 guide checklist" to="/articles/2020-guide-checklist/">2020 guide and checklist for selling your prestige car privately</Link>.</Paragraph1>
        
        <Calculator />
      </div>
    
    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>
)

export default calculatorPage

